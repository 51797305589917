<template>
    <div class="read_page">
        <layoutpage>
      
            <img class="banner" src="../../../static/image/ydzxbanner.png" />
            
            <div class="content">
                <div class="screen">
                    <el-row>
                        <el-button size="small" disabled type="text">选文类别</el-button>
                        <span v-for="(labelitem,labelindex) in label" :class="`${labelitem.select?'select':''}`" @click="selectLabel(labelitem.key)" :key="labelindex">{{ labelitem.value }}</span>

                    </el-row>
                    <el-row>
                        <el-button size="small" disabled type="text">选文难度</el-button>
                        <span v-for="(item,index) in grade" :class="`${item.select?'select':''}`" @click="selectGrade(index)" :key="index">{{ item.value }}</span>
                    </el-row>
                    <el-row>
                        <el-button size="small" disabled type="text">选择题型</el-button>
                        <span v-for="(item,index) in type" :class="`${item.select?'select':''}`" @click="selectType(index)" :key="index">{{ item.value }}</span>
                    </el-row>
                </div>
                <p class="screen_read">
                    <span @click="order('timedown')" :class="`${search_data.order == 'timedown'?'select':''} el-icon-sort-down`">时间降序</span>
                    <span @click="order('timeup')" :class="`${search_data.order == 'timeup'?'select':''} el-icon-sort-up`">时间升序</span>
                    <span @click="order('click')" :class="`${search_data.order == 'click'?'select':''} el-icon-hot-water`">文章热度</span>
                    <el-input placeholder="请输入材料标题" size="small" v-model="search_data.title" @change="changekeyword" clearable></el-input>
                </p>

                <el-table
                    :data="tableData_"
                    :cell-style="{cursor: 'pointer'}"
                    @row-click="handleRowClick"
                    style="width: 100%">
                    <el-table-column
                    type="index"
                    width="50">
                    </el-table-column>
                    <el-table-column
                    prop="title"
                    label="标题"
                    >
                    </el-table-column>
                    <el-table-column
                    prop="grade_text"
                    label="等级"
                    width="100"
                    >
                    </el-table-column>
                    <el-table-column
                    prop="type_text"
                    label="题型"
                    width="100">
                    </el-table-column>
                    <el-table-column
                    prop="label_text"
                    label="类别"
                    width="100">
                    </el-table-column>
      
                    <el-table-column
                    prop="click"
                    label="浏览"
                    width="100"
                    >
                    </el-table-column>
                    <el-table-column
                    prop="createtime"
                    label="发布时间"
                    width="100"
                    >
                    </el-table-column>
                </el-table>
                <el-pagination
                    v-if="total_number"
                    style="margin-top: 20px;"
                    @size-change="changeSize"
                    @current-change="changeNumber"
                    @prev-click="prev"
                    @next-click="next"
                    :page-size="search_data.pageSize"
                    :total="total_number">
                </el-pagination>
                <img style="margin-top: 20px;" src="../../../static/image/ydzxxwsm.png" />
            </div>

        </layoutpage>
    </div>
</template>
<script>
import Http from "../../apis/read";
export default {
    data(){
        return{
            cities: {1:'一级', 2:'二级', 3:'三级', 4:'四级',5:'五级',6:'六级',7:'七级'},
            label:{},
            type:{},
            grade:{},
            tableData_:[],
            total_number:0,
            search_data:{
                r_type:0,
                label:0,
                grade:0,
                title:'',
                order:'timedown',
                page:1,
                pageSize:20
            },
            row_type:0
        }
    },
    mounted(){
        this.row_type = this.$route.query.type
        this.getReadDiaoType()
        this.changeSearch()
    },
    activated(){
        
    },
    methods:{
        changekeyword(){
            this.changeSearch()
        },
        handleRowClick(row,event){
            var _this = this
            if(!window.sessionStorage.getItem("userinfo")){

                _this.$router.push({name:'Login',query:{redirect:encodeURIComponent(window.location.href)}});
                return false
            }
            _this.$router.push({name:'ReadDetail',query:{id:row.id,type:this.row_type}});
        },
        // 排序
        order(value){
            this.search_data.order = value
            this.changeSearch()
        },
        // 获取数据
        changeSearch(){
            
            Http.readIndex(this.search_data).then((res) => {
                this.total_number = res.data.count
                this.tableData_ = res.data.data
        
            });
        },

        // 选择类别
        selectLabel(index){
            var label = JSON.parse(JSON.stringify(this.label))
            for(var i in label){
                if(label[i].key == index){
                    label[i].select = true
                }else{
                    label[i].select = false
                }
            }
            this.search_data.label = index
            this.label = label

            this.changeSearch()
        },

        // 选择题型
        selectType(index){
            var type = JSON.parse(JSON.stringify(this.type))
            for(var i in type){
                if(i == index){
                    type[i].select = true
                }else{
                    type[i].select = false
                }
            }
            this.search_data.r_type = index
            this.type = type
            this.changeSearch()
        },
        // 选择等级
        selectGrade(index){
            var grade = JSON.parse(JSON.stringify(this.grade))
            for(var i in grade){
                if(i == index){
                    grade[i].select = true
                }else{
                    grade[i].select = false
                }
            }
            this.search_data.grade = index
            this.grade = grade
            this.changeSearch()
        },
        // 获取阅读题型、等级、分类
        getReadDiaoType(){
     
            Http.getReadType().then((res) => {
                // console.log(res.data.label)
                // var label = []
                // for(let index in res.data.label){

                //     console.log(res.data.label[index])

                //     let result = {}
                //     result.key = index
                //     result.value = res.data.label[index]
                //     result.select = index == 0?true:false
                //     label.push(result)
                // }
                
                this.label = res.data.label

                var type = []
                for(let index in res.data.type){
                    let result = {}
                    result.key = index
                    result.value = res.data.type[index]
                    result.select = index == 0?true:false
                    type.push(result)
                }
                this.type = type

                var grade = []
                for(let index in res.data.grade){
                    let result = {}
                    result.key = index
                    result.value = res.data.grade[index]
                    result.select = index == 0?true:false
                    grade.push(result)
                }
                this.grade = grade
            });
        },

        // 上一页
        prev(e){

            this.search_data.page = e
            this.changeSearch()
        },

        // 下一页
        next(e){

            this.search_data.page = e
            this.changeSearch()
        },

        // 点击页码
        changeNumber(e){

            this.search_data.page = e
            this.changeSearch()
        },

        // 前往指定页数
        changeSize(e){

            this.search_data.page = e
            this.changeSearch()
        }
    }
}
</script>
<style scoped>
.banner{
    width: 100%;
    height: 360px;
}
.content{
    width: 1200px;
    margin: 0 auto;
}
.screen{
    width: 100%;
    box-sizing: border-box;
    padding: 20px;
    text-align: left;
    border-radius: 6px;
    margin-top: 15px;
    background: #ffffff;
}
.content .screen >>> .is-disabled{
    height: 70px;
    font-size: 18px;
    font-weight: 700;
    color: #000000;
    margin-right: 20px;
}
.content .screen span{
    display: inline-block;
    line-height: 1;
    white-space: nowrap;
    cursor: pointer;
    background: #FFF;
    border: 1px solid #DCDFE6;
    color: #606266;
    -webkit-appearance: none;
    text-align: center;
    box-sizing: border-box;
    outline: 0;
    margin: 0;
    transition: .1s;
    font-weight: 500;
    padding: 9px 15px;
    font-size: 12px;
    border-radius: 3px;
    margin-right:10px;
    margin-bottom: 10px;
}
.content >>> .el-pagination{
    text-align: center;
    margin-top: 1vw;
}
.select{
    background: #409EFF !important;
    color: #ffffff !important;
    border-color: #409EFF !important;
}

.screen_read {
    text-align: left;
}
.screen_read span{
    display: inline-block;
    line-height: 1;
    white-space: nowrap;
    cursor: pointer;
    background: #FFF;

    color: #606266;
    -webkit-appearance: none;
    text-align: center;
    box-sizing: border-box;
    outline: 0;
    margin: 0;
    transition: .1s;
    font-weight: 500;
    padding: 9px 15px;
    font-size: 12px;
    border-radius: 3px;
    margin-right:10px;
    margin-bottom: 10px;
}
.screen_read >>> .el-input{
    
    width: 400px;
    float: right;
}
</style>